var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.is_detail)?_c('div',{staticStyle:{"position":"relative"}},[_c('baidu-map',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.mapLoading),expression:"mapLoading"}],staticClass:"bm-view",attrs:{"center":_vm.center,"zoom":_vm.zoom,"average-center":true},on:{"zoomstart":_vm.zoomstart,"ready":_vm.handler}},[_c('bm-navigation',{attrs:{"anchor":"BMAP_ANCHOR_TOP_RIGHT"}}),_vm._l((_vm.markers),function(marker,index){return _c('bm-marker',{key:index,attrs:{"position":{
          lng: marker.lng,
          lat: marker.lat,
        }},on:{"click":function($event){return _vm.handle(marker.id)}}},[_c('bm-label',{attrs:{"content":marker.station_name,"labelStyle":{
            color: 'rgba(0, 155, 255, 1)',
            borderColor: 'rgba(0, 155, 255, 1)',
            fontSize: '12px',
          },"offset":{ width: -35, height: 30 }}})],1)}),_c('bm-info-window',{attrs:{"position":_vm.position,"title":"Info Window Title","show":_vm.infoWindow.show},on:{"close":_vm.infoWindowClose,"open":_vm.infoWindowOpen}},[_c('p',{domProps:{"textContent":_vm._s(_vm.infoWindow.contents)}})])],2)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }