<template>
  <div>
    <header class="u-flex u-row-center u-rela">
      <i class="icon-logo shou" @change="goto"></i>
      <span class="title u-m-l-26 shou" @click="goto">{{ title }}</span>
      <div class="left u-font-32 u-flex u-abso">
        <div
          class="icon-wea"
          style="visibility: hidden"
          v-if="weatherType === 1"
        ></div>
        <div class="tianqi" style="visibility: hidden" v-else></div>
        <span class="u-m-l-12">{{ weather }}</span>
        <!--        <div class="line"></div>
        <span>安全运行天数：</span
        ><i class="oswald u-font-50 blue u-m-r-10">{{ safe_day }}</i
        ><span class="u-font-24">天</span>-->
      </div>
      <div class="right u-font-32 u-flex u-abso">
        <i class="icon-time"></i>
        <span class="u-m-l-10">{{ nowDate }}</span>
        <i class="line"></i>
        <span class="oswald u-font-50 blue u-m-r-10"> {{ nowTime }} </span>
        <i class="line"></i>
        <span class="icon-msg u-flex u-flex-col u-row-center" @click="goto">
          <i class="msg"></i>
          <span class="u-m-t-4">返回</span>
        </span>
      </div>
    </header>
    <router-view class="page-wrap" />

    <!-- 最新报警newAlarm 弹窗 -->
    <el-dialog
      class="custom-dialog index-dialog"
      :visible.sync="newAlarmDialog"
    >
      <template v-slot:title>
        <h3>报警信息</h3>
      </template>
      <div class="table-body">
        <table class="table1">
          <colgroup>
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
          </colgroup>
          <tr class="title">
            <td>报警时间</td>
            <td>报警站点</td>
            <td>报警位置</td>
            <td>报警内容</td>
            <td>报警等级</td>
          </tr>
          <tr v-for="(item, index) in newAlarmList" :key="index">
            <td>{{ item.alarmTime }}</td>
            <td>{{ item.serviceName }}</td>
            <td>{{ item.objectDesc }}</td>
            <td>{{ item.desc }}</td>
            <td :class="getName(item.alarmLevel)">{{ item.alarmLevel }}</td>
          </tr>
        </table>
      </div>
      <!-- 分页器 -->
      <!--      <el-pagination
        class="u-m-t-20 u-flex u-row-right u-p-r-60"
        layout="total,prev, pager, next,jumper,slot"
        :total="50"
      >
        <div class="btn" @click="clickEnter()">确定</div>
      </el-pagination>-->
      <div class="off" @click="newAlarmDialog = false"></div>
    </el-dialog>
  </div>
</template>

<script>
import { getSafeDays, getWeather, getAlarms } from "@/api/screen";
import screenfull from "screenfull";
export default {
  name: "Head",
  data() {
    return {
      // eslint-disable-next-line no-undef
      title: this.$store.state.screen.title,
      server_id: this.$store.state.screen.server_id,
      safe_day: 0,
      weatherType: 1,
      weather: "",
      nowDate: null, //存放年月日变量
      nowTime: null, //存放时分秒变量
      timer: "", //定义一个定时器的变量
      newAlarmDialog: false,
      currentTime: new Date(), // 获取当前时间
      newAlarmList: [
        {
          name: "2022-02-23 14:15:36",
          adds: "长沙先导",
          addsname: "新厂房节点服务器通讯",
          nav: "报警内容内容内容",
          grade: "一般",
        },
        {
          name: "2022-02-23 14:15:36",
          adds: "长沙先导",
          addsname: "新厂房节点服务器通讯",
          nav: "报警内容内容内容",
          grade: "可疑",
        },
        {
          name: "2022-02-23 14:15:36",
          adds: "长沙先导",
          addsname: "新厂房节点服务器通讯",
          nav: "报警内容内容内容",
          grade: "一般",
        },
        {
          name: "2022-02-23 14:15:36",
          adds: "长沙先导",
          addsname: "新厂房节点服务器通讯",
          nav: "报警内容内容内容",
          grade: "一般",
        },
        {
          name: "2022-02-23 14:15:36",
          adds: "长沙先导",
          addsname: "新厂房节点服务器通讯",
          nav: "报警内容内容内容",
          grade: "一般",
        },
        {
          name: "2022-02-23 14:15:36",
          adds: "长沙先导",
          addsname: "新厂房节点服务器通讯",
          nav: "报警内容内容内容",
          grade: "致命",
        },
        {
          name: "2022-02-23 14:15:36",
          adds: "长沙先导",
          addsname: "新厂房节点服务器通讯",
          nav: "报警内容内容内容",
          grade: "一般",
        },
        {
          name: "2022-02-23 14:15:36",
          adds: "长沙先导",
          addsname: "新厂房节点服务器通讯",
          nav: "报警内容内容内容",
          grade: "一般",
        },
        {
          name: "2022-02-23 14:15:36",
          adds: "长沙先导",
          addsname: "新厂房节点服务器通讯",
          nav: "报警内容内容内容",
          grade: "操作",
        },
        {
          name: "2022-02-23 14:15:36",
          adds: "长沙先导",
          addsname: "新厂房节点服务器通讯",
          nav: "报警内容内容内容",
          grade: "一般",
        },
        {
          name: "2022-02-23 14:15:36",
          adds: "长沙先导",
          addsname: "新厂房节点服务器通讯",
          nav: "报警内容内容内容",
          grade: "操作",
        },
        {
          name: "2022-02-23 14:15:36",
          adds: "长沙先导",
          addsname: "新厂房节点服务器通讯",
          nav: "报警内容内容内容",
          grade: "可疑",
        },
        {
          name: "2022-02-23 14:15:36",
          adds: "长沙先导",
          addsname: "新厂房节点服务器通讯",
          nav: "报警内容内容内容",
          grade: "可疑",
        },
      ],
      project_id: 1,
    };
  },
  created() {
    this.timer = setInterval(this.getTime, 1000);
    this.getName();
    this.getSafeDays();
    this.getWeather();
    // this.getAlarmNum();
    this.getAlarms();
    setInterval(() => {
      this.getWeather();
    }, 3600000);
  },
  mounted() {},
  methods: {
    getAlarms() {
      getAlarms().then((response) => {
        console.log("alarms", response);
        for (let i = 0; i < response.res.length; i++) {
          this.newAlarmList.push({
            alarmTime: response.res[i].THETIME,
            serviceName: response.res[i].stationName,
            objectDesc: response.res[i].OBJDESC,
            desc: response.res[i].DESCRIPTION,
            alarmLevel: response.res[i].NAME,
          });
        }
      });
    },
    clickFullscreen() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: "浏览器不支持",
          type: "warning",
        });
        return false;
      }
      screenfull.toggle();
      this.isFullscreen = true;
    },
    checkFull() {
      var isFull =
        document.fullscreenEnabled ||
        window.fullScreen ||
        document.webkitIsFullScreen ||
        document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
    getWeather() {
      getWeather({}).then((res) => {
        if (res.res.value.indexOf("晴") !== -1) {
          this.weatherType = 1;
        } else {
          this.weatherType = 2;
        }
        this.weather = res.res.value;
      });
    },

    getSafeDays() {
      getSafeDays({ project_id: this.project_id }).then((res) => {
        this.safe_day = res.res.safe_days;
      });
    },
    getName(name) {
      if (name == "可疑") {
        return "yellow1";
      } else if (name == "一般") {
        return "orange";
      } else if (name == "致命") {
        return "red";
      } else if (name == "操作") {
        return "violet";
      }
    },
    //时间加载
    getTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();

      if (this.hour > 12) {
        this.hour -= 12;
        this.str = " AM";
      }
      this.month = check(month);
      this.day = check(day);
      this.hour = check(hour);
      this.minute = check(minute);
      this.second = check(second);
      function check(i) {
        const num = i < 10 ? "0" + i : i;
        return num;
      }
      this.nowDate = year + "年" + this.month + "月" + this.day + "日";
      this.nowTime = this.hour + ":" + this.minute + ":" + this.second;
    },
    goto() {
      window.location.href =
        "http://zhongjianqijusolar.xjpmf.cloud/#/firstpage?token=82314|IsR3fx5zRzCjHGB9J4cLMk5vzwx9cBthS5EogUCM";
    },
    //点击消息弹窗
    showlod() {
      console.log(111);
    },
  },
};
</script>
<style lang="scss" scoped>
.table1 {
  tr {
    height: 0.7rem;
    background: #011760;
    text-align: center;
    font-size: 0.22rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  tr:nth-of-type(odd) {
    background: rgba(0, 0, 0, 0.1);
  }
  .shou {
    cursor: pointer;
  }
  .title {
    height: 0.7rem;
    background: #0f2368 !important;
    font-size: 0.28rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #009cff;
  }
}
</style>
