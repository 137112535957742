<template>
  <el-dialog
    class="custom-dialog pagetwo-dialog"
    :visible.sync="distrInfoDialogVisible"
    :width="'90%'"
  >
    <template v-slot:title>
      <h3
        class="u-flex u-col-cneter u-row-between"
        style="width: 100%; padding-right: 1.2rem"
      >
        <span>电站详情</span>
        <div class="goPush" style="cursor: pointer" @click="goPush">返回</div>
      </h3>
    </template>
    <!-- 基础信息 -->
    <el-scrollbar style="height: 700px">
      <!-- 滚动条 -->
      <div>
        <span
          class="u-font-38 blod cyan1"
          style="display: flex; align-items: center"
        >
          <i
            v-if="detail.state === 1"
            class="online_backgroundColor"
            style="margin-right: 5px"
          />
          <i
            v-if="detail.state === 2"
            class="offline_backgroundColor"
            style="margin-right: 5px"
          />
          <i
            v-if="detail.state === 3"
            class="fault_backgroundColor"
            style="margin-right: 5px"
          />{{ detail.name }}</span
        >
        <el-row :gutter="20" class="base-info u-m-t-10 u-m-b-40">
          <el-col :span="24">
            <div class="u-flex u-m-t-20 warp" style="flex-wrap: wrap">
              <span style="width: 33%">状态:{{ detail.state_desc }}</span>
              <span style="width: 33%"
                >组件容量:{{ detail.capacity.value
                }}{{ detail.capacity.unit }}</span
              >

              <span style="width: 33%">上电时间:{{ detail.created_at }}</span>

              <span style="width: 33%"
                >今日发电:{{ detail.day_energy.value }}
                {{ detail.day_energy.unit }}</span
              >
              <span style="width: 33%"
                >当月发电:{{ detail.month_energy.value }}
                {{ detail.month_energy.unit }}</span
              >

              <span style="width: 33%"
                >累计发电:{{ detail.all_energy.value }}
                {{ detail.all_energy.unit }}</span
              >

              <span style="width: 33%"
                >今日收益:{{ detail.day_in_come }} 元</span
              >
              <span style="width: 33%"
                >当月收益:{{ detail.month_in_come }} 元</span
              >
              <span style="width: 33%"
                >累计收益:{{ detail.all_in_come }} 元</span
              >
            </div>
          </el-col>
        </el-row>
      </div>
      <!--      <div v-for="item in detail.list_info" :key="item.name">
        <span class="u-font-38 blod cyan1">{{ item.name }}</span>
        <el-row :gutter="20" class="base-info u-m-t-10 u-m-b-40">
          <el-col :span="24">
            <div class="u-flex u-m-t-20 warp" style="flex-wrap: wrap">
              <span v-for="filed in item.filed" :key="filed.key"
                >{{ filed.name }}：{{ filed.value }}{{ filed.unit }}</span
              >
            </div>
          </el-col>
        </el-row>
      </div>-->
      <!-- 历史数据 -->
      <span class="u-font-38 blod cyan1">历史数据</span>
      <div class="u-rela u-m-t-10">
        <div class="u-flex u-row-right u-rela right-action">
          <div class="datetab u-flex">
            <span
              :class="historyTab == 0 ? 'active' : ''"
              @click="historyTabChage(0)"
              >日</span
            >

            <span
              :class="historyTab == 2 ? 'active' : ''"
              @click="historyTabChage(2)"
              >月</span
            >
            <span
              :class="historyTab == 3 ? 'active' : ''"
              @click="historyTabChage(3)"
              >年</span
            >
            <!--            <span
              :class="historyTab == 4 ? 'active' : ''"
              @click="historyTabChage(4)"
              >总</span
            >-->
          </div>
          <el-date-picker
            v-if="historyTab == 0"
            class="u-m-l-30"
            v-model="historydate"
            value-format="yyyy-MM-dd 00:00:00"
            type="date"
            @change="getEquipmentEquipDay"
            placeholder="选择日期"
          >
          </el-date-picker>
          <el-date-picker
            v-if="historyTab == 1"
            class="u-m-l-30"
            v-model="historydate"
            format="yyyy 第 WW 周"
            value-format="yyyy-MM-dd 00:00:00"
            type="week"
            @change="getEquipmentEquipWeek"
            placeholder="选择日期"
          >
          </el-date-picker>
          <el-date-picker
            v-if="historyTab == 2"
            class="u-m-l-30"
            v-model="historydate"
            value-format="yyyy-MM-dd 00:00:00"
            type="month"
            @change="getEquipmentEquipMonth"
            placeholder="选择日期"
          >
          </el-date-picker>
          <el-date-picker
            v-if="historyTab == 3"
            class="u-m-l-30"
            v-model="historydate"
            value-format="yyyy-MM-dd 00:00:00"
            type="year"
            @change="getEquipmentEquipYear"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div
        v-if="historyTab == 3 || historyTab == 2"
        style="display: flex; justify-content: center; color: #fff"
      >
        总发电量 :{{ total.energy }} 总收益 :{{ total.money }}
      </div>

      <div class="chart u-m-b-36" id="line2"></div>
      <!-- 报警信息 -->
      <span class="u-font-38 blod cyan1">报警信息</span>
      <div class="u-flex u-m-t-20">
        <el-button class="btn btn-blue">全部</el-button>
        <el-button class="btn btn-blue u-m-l-20">发生中</el-button>
        <el-button class="btn btn-blue u-m-l-20">已恢复</el-button>
      </div>
      <el-table :data="warnInfoList" style="width: 100%">
        <el-table-column prop="name" label="信息名称"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间"></el-table-column>
        <el-table-column prop="reason" label="报警原因"></el-table-column>
      </el-table>
    </el-scrollbar>
    <div class="off" @click="distrInfoDialogVisible = false"></div>
  </el-dialog>
</template>

<script>
import * as echarts from "echarts";
import { line2Option } from "@/assets/js/charts";
import { guanfuOption } from "@/echarts/guangfu";
import { getReport } from "@/api/guangfu";
import { getLine3, getLine4 } from "@/assets/js/charts";
import { color, hex2rgb } from "@/unit/common-params";
import * as dayjs from "dayjs";
import {
  getEquipParameter,
  getEquipmentEquipWeek,
  getEquipmentEquipYear,
  getChartsTemplate,
  setChartsTemplate,
} from "@/api/screen2";
import { detail } from "@/api/guangfu";
export default {
  name: "detail",
  data() {
    return {
      paramsColor: color,
      paramsPopShow: false,
      templatePopShow: false, //保存为模板弹窗
      distrInfoDialogVisible: false,
      historyTab: 0,
      id: "",
      systemTpl: [],
      custom: [],
      customTab: 10000,
      historydateWeek: new Date(),
      paramsOption: [
        {
          value: "all",
          label: "全部",
        },
      ],
      total: {
        energy: "",
        money: "",
      },
      telemeterList: ["选中且禁用", "复选框 A"], //遥测复选框
      remotePulseList: ["选中且禁用", "复选框 A"],
      paramsValue: "",
      tpl_name: "",
      server_id: this.$store.state.screen.server_id,
      historydate: new Date(),
      systemTab: 0,
      detail: {},
      warnInfoList: [],
      analog: [],
      acc: [],
      equipParameter: [],
    };
  },
  created() {},
  methods: {
    getEquipParameter() {
      getEquipParameter({ id: this.id, server_id: this.server_id }).then(
        (res) => {
          this.equipParameter = res.data;
        }
      );
    },
    handleCustomTab(index) {
      this.customTab = index;
      this.analog = this.custom[index].setting.analog.map((item) => {
        return parseInt(item);
      });
      this.acc = this.custom[index].setting.acc.map((item) => {
        return parseInt(item);
      });
      this.systemTab = 10000;

      this.requestApi();
    },
    exportExcel() {
      let type = "day";
      switch (this.historyTab) {
        case 0:
          type = "day";
          break;
        case 1:
          type = "week";
          break;
        case 2:
          type = "month";
          break;

        case 3:
          type = "year";
          break;
      }
      let time = dayjs(this.historydate).format("YYYY-MM-DD HH:mm:ss");
      time = encodeURIComponent(time);
      console.log("time", time);
      window.open(
        // eslint-disable-next-line no-undef
        PLATFROM_CONFIG.baseUrl +
          "/api/v2/exportEquipmentReport?server_id=" +
          this.server_id +
          "&id=" +
          this.id +
          "&time=" +
          time +
          "&type=" +
          type
      );
    },

    getChartsTemplate() {
      getChartsTemplate({ id: this.id, server_id: this.server_id }).then(
        (res) => {
          console.log(res);
          this.systemTpl = res.res.system;
          this.analog = res.res.system[0].setting.analog.map((item) => {
            return parseInt(item);
          });
          this.acc = res.res.system[0].setting.acc.map((item) => {
            return parseInt(item);
          });
          this.custom = res.res.custom;
          this.requestApi();
        }
      );
    },
    //设置模板
    setChartsTemplate() {
      if (!this.tpl_name) {
        alert("请填写模板名称");
        return;
      }
      if (this.analog.length + this.acc.length == 0) {
        alert("请选择参数");
        return;
      }
      let number = this.custom.push({
        name: this.tpl_name,
        setting: {
          analog: this.analog,
          acc: this.acc,
        },
      });
      this.paramsPopShow = false;
      this.templatePopShow = false;
      this.handleCustomTab(number - 1);

      setChartsTemplate({
        id: this.id,
        server_id: this.server_id,
        analog: this.analog,
        name: this.tpl_name,
        acc: this.acc,
      }).then((res) => {
        console.log(res);
      });
    },
    requestApi() {
      if (this.historyTab == 0) {
        //日
        this.getEquipmentEquipDay();
      } else if (this.historyTab == 2) {
        this.getEquipmentEquipMonth(1);
      } else if (this.historyTab == 3) {
        this.getEquipmentEquipMonth(2);
      } else if (this.historyTab == 4) {
        this.getEquipmentEquipMonth(3);
      }
    },

    getEquipmentEquipDay() {
      getReport({
        data_type: "date",
        time: this.historydate,
        energy_station_id: this.id,
      }).then((res) => {
        const data = res.data.data;
        const series = [];
        data.map((item, index) => {
          series.push({
            name: item.name,
            type: "line",
            data: item.data,
            showSymbol: false,
            smooth: true,
            lineStyle: {
              color: this.paramsColor[index],
              width: this.getDpr(2),
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: this.paramsColor[index], // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: hex2rgb(this.paramsColor[index], 0), // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: hex2rgb(this.paramsColor[index], 0.5), // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: this.paramsColor[index], // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          });
        });
        console.log(series);
        this.$nextTick(() => {
          let myChart = echarts.init(document.getElementById("line2"));
          line2Option.series = series;
          myChart.setOption(line2Option, true);
        });
      });
    },
    getEquipmentEquipMonth(type) {
      let date_type = "month";
      if (type === 2) {
        date_type = "year";
      } else if (type === 3) {
        date_type = "all";
      }
      getReport({
        data_type: date_type,
        time: this.historydate,
        energy_station_id: this.id,
      }).then((res) => {
        this.total = res.data.total;
        const data = res.data;

        this.$nextTick(() => {
          const myChart = echarts.init(document.getElementById("line2"));
          const yxis = [];
          data.unit.forEach((item, index) => {
            console.log(index);
            yxis.push({
              type: "value",
              name: item,
              nameLocation: "end",
              scale: true,
              axisTick: {
                show: false,
              },

              axisLine: {
                show: true,
                lineStyle: {
                  color: "rgba(0, 156, 249, 0.4)",
                },
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(0, 156, 249, 0.3)",
                },
              },
              position: index % 2 == 0 ? "left" : "right",
              offset: parseInt(index / 2) * 20,
            });
          });
          const series = [];
          data.data.forEach((item, index) => {
            console.log(index);
            if (item.type === "bar") {
              series.push({
                name: item.name,
                type: item.type,
                data: item.data,
                barWidth: "20%",
                showBackground: true,
                backgroundStyle: {
                  color: "rgba(255, 255, 255, 0.07)",
                  borderColor: "rgba(0,121,120,1)",
                  borderWidth: 0,
                  borderRadius: 0,
                  shadowColor: "rgba(0,121,120,0)",
                  shadowBlur: 0,
                },
                label: {
                  show: false,
                  position: "top",
                  textStyle: {
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: 700,
                    fontFamily: "Oswald",
                  },
                },
                itemStyle: {
                  // barBorderRadius: 0,
                  // borderColor: "#12A3FF",
                  borderWidth: 1,
                  barBorderRadius: 15,
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(0, 155, 255, 1)", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "rgba(0, 155, 255, 0)", // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                },
              });
            } else {
              series.push({
                name: item.name,
                type: item.type,
                data: item.data,
                showSymbol: false,
                yAxisIndex: item.yAxisIndex,
                smooth: true,
                lineStyle: {
                  color: "rgba(76, 187, 255, 1)",
                },
                itemStyle: {
                  color: "rgba(76, 187, 255, 1)",
                },
              });
            }
          });
          guanfuOption.yAxis = yxis;
          guanfuOption.series = series;
          guanfuOption.legend.data = data.name;
          console.log("option", guanfuOption);
          myChart.setOption(guanfuOption, true);
        });
      });
    },
    getDpr(num) {
      let width = window.screen.width;
      if (width > 1920) {
        return num;
      } else {
        return num / 2;
      }
    },
    getEquipmentEquipYear() {
      getEquipmentEquipYear({
        server_id: this.server_id,
        time: this.historydate,
        id: this.id,
        analog: this.analog,
        acc: this.acc,
      }).then((res) => {
        console.log(res);
        let data = res.res;
        this.$nextTick(() => {
          getLine4("line2", data);
        });
      });
    },
    getEquipmentEquipWeek() {
      getEquipmentEquipWeek({
        server_id: this.server_id,
        time: this.historydate,
        id: this.id,
        analog: this.analog,
        acc: this.acc,
      }).then((res) => {
        console.log(res);
        let data = res.res;
        this.$nextTick(() => {
          getLine3("line2", data);
        });
      });
    },
    historyTabChage(tap_id) {
      if (this.historyTab !== tap_id) {
        this.historyTab = tap_id;
        this.requestApi();
      }
    },
    open(id) {
      this.id = id;
      this.distrInfoDialogVisible = true;
      this.systemTab = 0;
      this.custom = 10000;
      this.getEquipmentDetail();
      this.getEquipParameter();
      this.getChartsTemplate();
    },
    getEquipmentDetail() {
      detail({ id: this.id }).then((res) => {
        this.detail = res.data;
      });
    },
    // 系统模块切换
    handleSystemTab(index) {
      this.systemTab = index;
      this.customTab = 10000;
      this.analog = this.systemTpl[index].setting.analog;
      this.acc = this.systemTpl[index].setting.acc;
      this.requestApi();
    },
    //清空选择
    clearParam() {
      this.analog = [];
      this.acc = [];
    },
    goPush() {
      this.distrInfoDialogVisible = false;
      this.$emit("change");
    },
    //
    sureParam() {
      this.paramsPopShow = false;

      if (this.historyTab == 0) {
        //日
        this.getEquipmentEquipDay();
      } else if (this.historyTab == 1) {
        this.getEquipmentEquipWeek();
      }
    },
  },
};
</script>

<style scoped>
/deep/ .pagetwo-page .pagetwo-dialog .el-dialog {
  width: 40.51rem !important;
}
.offline_backgroundColor {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  float: left;
  background-color: #ccc !important;
}
.online_backgroundColor {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  float: left;
  background: #14dbff;
}

.fault_backgroundColor {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  float: left;
  background-color: #d45559 !important;
}
</style>
